"use strict";

$(".openable").each((i, openable) => {
  const content = $(openable).find("> .content");
  const toggle = $(openable).find("> .toggle");

  toggle.on("click", () => {
    $(openable).toggleClass("open");
    content.slideToggle();
  });
});
